<template>
  <div
    class="bg-white w-full  px-4 pt-5 pb-8 shadow-md mb-2"
  >
    <div class="mb-3 md:flex items-center justify-between">
      <h3
        class="font-bold sm:w-2/5 text-2xl text-untitled-gray-700 line-clamp-2"
      >
        {{ title }}
      </h3>
      <div class="flex flex-row gap-2 justify-end items-center">
        <div
          class="flex gap-1 peer-hover:hidden beer-hover:hidden items-center text-untitled-gray-600"
        >
          <VideoCameraIcon class="h-4 w-4" aria-hidden="true" />

          <p class="text-xs">{{ totalVideos }} videos</p>
        </div>
        <template v-if="dueDate">
          <div
            class="flex gap-1 peer-hover:hidden items-center text-untitled-gray-600"
          >
            <CalendarIcon class="h-4 w-4" aria-hidden="true" />

            <p class="text-xs">{{ dueDate }}</p>
          </div>
        </template>
        <div
          class="flex gap-1 peer-hover:hidden items-center text-untitled-gray-600"
        >
          <PresentationChartLineIcon class="h-4 w-4" aria-hidden="true" />
          <p class="text-xs">
            {{ totalVideosCompleted }}/{{ totalVideos }} videos completed
          </p>
     

          <Menu as="div" class="absolute top-1/2 right-0 text-center" v-if="isSuperAdmin || isOwner" >
            <div>
              <MenuButton class="inline-flex justify-center rounded-md  pt-4 text-sm font-medium text-gray-700">
                <DotsVerticalIcon class="h-6 w-6 mr-1 mt-2" aria-hidden="true" />
              </MenuButton>
            </div>

            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div class="py-1">
                  <MenuItem v-slot="{ active }" v-if="!hasPaidContents">
                    <a href="#"  @click="openDialog()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Assign Playlist</a>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <router-link :to="'/edit/playlist/' + pathId" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Edit Playlist</router-link>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <a href="#"  @click="confirmShow = true" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Delete Playlist</a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>

        </div>
      </div>
    </div>
    <div class="md:flex items-center gap-3">
      <div class="md:w-full">
        <span @click="goToLearningPath()">
          <div class="relative cursor-pointer">
            <img
              v-lazy="{
                src: thumbnail,
                error:
                  'https://lms-v2-staging.s3-us-west-2.amazonaws.com/modules/default-thumbnail.jpg',
                loading:
                  'https://via.placeholder.com/800x450?text=Thumbnail%20Loading...',
              }"
              class="object-scale-down aspect-video w-full lg:w-[60%]"
            />

            <div
              class="absolute w-full lg:w-[60%] bottom-0 h-full object-center inset-0 place-items-center bg-gradient-to-t from-black opacity-50 text-white text-xs text-center leading-4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="mt-[18%] object-center place-items-center inset-0 mx-auto w-12 h-12"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                />
              </svg>
            </div>
          </div>
        </span>
      </div>
      <div class="w-full">
        <div class="container mx-auto">
          <div class="w-full gap-2 mx-auto mt-5 md:mt-5">
            <div class="flex justify-end gap-1 flex-col md:flex-row" v-if="isSuperAdmin || isOwner">
              <a
                href="#"
                @click="openDialog()"
                class="flex w-full justify-center rounded-md border border-transparent bg-untitled-gray-200 py-2 px-4 font-medium text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-500 focus:ring-offset-2 text-xs"
              >
                <small>Assign Playlist</small>
              </a>
              <router-link
                :to="'/edit/playlist/' + pathId"
                class="flex w-full justify-center rounded-md border border-transparent bg-untitled-gray-200 py-2 px-4 font-medium text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-500 focus:ring-offset-2 text-xs"
              >
                <small>Edit Playlist</small>
              </router-link>
              <a
                href="#"
                @click="confirmShow = true"
                class="flex w-full justify-center rounded-md border border-transparent bg-untitled-gray-200 py-2 px-4 font-medium text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-500 focus:ring-offset-2 text-xs"
              >
                <small>Delete Playlist</small>
              </a>
            </div>
            <div class="flex justify-end gap-1 flex-col md:flex-row" v-else>
              <button
                @click="goToLearningPath()"
                class="flex w-full justify-center rounded-md border border-transparent bg-untitled-gray-200 py-2 px-4 font-medium text-untitled-gray-800 shadow-sm hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-untitled-gray-500 focus:ring-offset-2 text-xs"
              >
                <small>Open Playlist</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <assign-playlist-dialog
    v-if="assignPlaylistDialogOpen"
    :is-open="assignPlaylistDialogOpen"
    :close="closeAssignPlaylistDialog"
    :playlist-id="pathId"
  />
  <ConfirmDialog
    :okButton="deletePlaylist"
    :cancelButton="onClickConfirmCancel"
    :open="confirmShow"
    id="UnitPage-ConfirmDialog-Dialogbox"
    >{{ confirmMessage }}
  </ConfirmDialog>
</template>

<script setup>
/* eslint-disable */
import AssignPlaylistDialog from "@/components/learning-paths/AssignPlaylistDialog.vue";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { defineProps, ref, inject, computed } from "vue";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores";
import {
  DotsVerticalIcon,
  // DotsHorizontalIcon,
  VideoCameraIcon,
  CalendarIcon,
  // CogIcon,
  PresentationChartLineIcon,
} from "@heroicons/vue/outline";
import { find as _find } from "lodash-es";
import { useLearningPathStore } from "@/stores/learningPath";

const props = defineProps({
  contents: {
    type: Array,
    default: () => [],
  },
  isOwner: {
    type: Boolean,
    required: true,
  },
  reload: {
    type: Function,
  },
  pathId: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
  totalVideoDuration: {
    type: String,
    default: null,
  },
  totalVideos: {
    type: Number,
    default: 0,
  },
  totalVideosCompleted: {
    type: Number,
    default: 0,
  },
  videoThumbnail: {
    type: String,
    default: null,
  },
  dueDate: {
    type: String,
    default: null,
  },
  thumbnail: {
    type: String,
    default: null,
  },
  hasPaidContents: {
    type: Boolean,
    default: false
  }
});
const authUser = useAuthStore();
const confirmMessage = ref("Are you sure you want to delete this playlist?");
const confirmShow = ref(false);
const axios = inject("axios");
const learningPath = useLearningPathStore();
const hasPaidContents = computed(() => props.hasPaidContents)

const isSuperAdmin = computed(() => {
  const user = authUser.$state.user;
  if (!user) {
    return false;
  }
  const isExists = user.roles.find(
    (role) => role.name === "super-administrator"
  );

  return isExists;
});

const router = useRouter();
const assignPlaylistDialogOpen = ref(false);
function goToLearningPath() {
  const { contents, pathId } = props;

  const currentUnit = _find(contents, { is_completed: false });

  let unitId = null;

  if (currentUnit === undefined) {
    unitId = props.contents[0].id;
  } else {
    unitId = currentUnit.id;
  }

  router.push({
    name: "units",
    params: {
      id: unitId,
      playlistId: pathId,
    },
  });
}
function openDialog() {
  assignPlaylistDialogOpen.value = true;
}

function closeAssignPlaylistDialog(){
  assignPlaylistDialogOpen.value = false 
}
async function deletePlaylist() {
  await axios.delete(`/api/v3/learning-paths/${props.pathId}`).then(() => {
    learningPath.removeLearningPath(props.pathId);
    confirmShow.value = false;
  });
}
function onClickConfirmCancel() {
  confirmShow.value = false;
}
</script>
<style scoped></style>
